.contact-card {
    width: 100%;
    @include mq(768px) {

        max-width: 280px;
    }
    & > .btn {
        width: 100%;
        border: 1px solid color(secondary,.1);
        border-radius: 8px;
        box-shadow: 0 0 5px rgba(0,0,0,0.03);
        padding: 15px;
        display: inline-block;
        text-align: left;
        text-transform: none;
        &:hover {
            .card-link-icon {
                opacity: 0.3;
            }
        }
    }
    .card {
   
        &-icon {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            svg {
                width: 30px;
                height: 30px;
                object-fit: contain;
            }
        }
    
        &-link-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 15px;
            color: color(secondary);
            opacity: 0;
            transition: opacity .3s ease;
        }
        &-pathname {
            font-size: 12px;
            color: color(secondary,.5);
        }
    }
}