.campaigns-page {
    & > .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px 30px;
        justify-content: space-between;
        margin-bottom: 25px;
    }


}
