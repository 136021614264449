.campaign-info-td {
    padding: 20px !important;
}
.campaign-info {
    &-body {
        
        display: flex;
        align-items: flex-start;
        // flex-wrap: wrap;
        width: 100%;
        // padding: 10px 10px;
        min-height: 150px;
        // gap: 30px;
        gap: 30px;
        position: relative;
        justify-content: space-between;
    }
    &-footer {
        background-color: color(light,.2);
        padding: 10px 15px;
        border-radius: 8px;
        position: relative;
        img {
            position: absolute;
            right:25px;
            top: 50%;
            transform: translateY(-50%);
            max-width: 40px;
            opacity: 0.3;
            filter: grayscale(100%);
        }
    }
    .wrapper {
        width: calc(100% / 3);
        // flex: 1;
        font-size: 12px;
        font-weight: 500;
        max-width: 300px;
        // max-width: 100%;
        
        .title {
            font-size: 16px;
            margin-bottom: 10px;
        }
        .row {
            display: flex;
            gap: 5px;
            // align-items: flex-start;
            align-items: center;
            margin-bottom: 8px;
        }
        .col {
            &.name {
                font-size: 12px;
                font-weight: 500;
                color: color(secondary, 0.9);
            }
            &.value {
                color: color(primary);
                overflow-x: hidden;
                text-overflow: ellipsis;
                font-size: 10px;
            }
            &.name,
            &.value {
            }
        }

        &.ads-details {
            display: flex;
            flex-direction: column;
            max-width: 300px;
           
            .title {
                padding: 0 10px;
            }
            .table-wrapper {
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 5px;
            }
            .table-scroll {
                margin: 3px;
                margin-right: 1px;
                max-height: 250px;
    
                overflow-y: auto;
                
                &::-webkit-scrollbar {
                    width: 5px;
                }
            }
            table {
                width: 100%;
                flex: 1;
                padding: 3px;
                // border-collapse: collapse;
                // width: 500px;
            }
            td,
            th {
                // width: calc(100% / 3);
                padding: 5px 5px;
                font-size: 12px;

                .content {
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    //    width: 100%;
                    // max-width: 70px;
                }
            }
            td {
                max-width: 0;
                .content {
                    // display: inline-block;
                    // vertical-align: middle;
                    // padding: 0 6px;
                }
            }
        }

        .ads-th-status {
            text-align: center;
        }
        .ads-td-status {
            text-align: center;
        }

        .ads-td-title {
           
        }
        .ads-td-image {
            width: 0;
            text-align: center;
            .content {
                background-color: color(light);
                width: fit-content;
                margin: 0 auto;
                border-radius: 5px;
                overflow: hidden;
                img {
                    border-radius: 3px;
                    display: block;
                    width: 40px;
                    height: 30px;
                    object-fit: cover;
                    cursor: pointer;

                }
            }
        }
        .ads-td-status {
           
            width: 0;
            // width: 10px;
        }
       
        .image-tooltip {
            padding: 0px !important;
            border-radius: 35px !important;
            max-width: 300px;
            overflow: hidden;
            .image-wrapper {
                position: relative;
                width: 300px;
                height: 270px;
                // background-color: color(light);
                img {
                    position: absolute !important;
                    width: 100% !important;
                    height: 100% !important;
                    top: 0 !important;
                    left: 0 !important;
                    object-fit: contain !important;
                    // border-radius: 0 !important;
                    display: block;
                    // width: 100% !important;
                }
            }
        }
    
    }
}

// .campaign-details-tooltip {
//     white-space: normal;
//     opacity: 1 !important;
//     .value {
//     }
// }
