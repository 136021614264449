.select {
    position: relative;
    user-select: none;
    width: fit-content;
    // width: 100%;
    max-width: 300px;
    // margin-bottom: 15px;
    &.isLoading {
        pointer-events: none;
        .select-toggle-title {
            // pointer-events: none;
            opacity: 0;
        }
    }
    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .btn {
        &:hover {
            background-color: #fff;
        }
    }

    .btn {
        .select-toggle-title {
            color: color(dark) ;
        }
    }
    * {
        font-family: "Inter", "Helvetica", "Arial", sans-serif;
    }
    // .btn {
    //     all: unset;
    // }
    &-label {
        font-size: 14px;
        // color: color(dark);
        font-weight: bold;
        margin-bottom: 8px;
        display: inline-block;
    }
    &.active {
        .select {
            &-toggle {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                &-icon {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            &-menu {
                opacity: 1;
                transform: none;
                pointer-events: auto;
            }
        }
    }
    &-toggle,
    &-item {
        display: flex;
        align-items: center;
        .startIcon,
        .endIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            svg,
            img {
                height: 12px;
                width: 12px;
                object-fit: contain;
                fill: currentColor;
            }
        }
        .endIcon {
            margin-left: 10px;
        }
        .startIcon {
            margin-right: 10px;
        }
    }
    &-toggle {
        // padding: 6px 16px;
        padding: 4px 13px;
        padding-right: 35px;
        line-height: 1.75;
        background-color: #fff;

        cursor: pointer;
        width: 100%;
        justify-content: space-between;
        text-transform: none;
        & > .wrapper {
            display: flex;
            align-items: center;
            // width: 88%;
            overflow: hidden;
            &.placeholder {
                opacity: 0.4;
            }
        }
        &-title {
            flex: 1;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
           
            // &::first-letter {
            //     text-transform: uppercase;
            // }
        }
        &-icon {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right: 3px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 !important;
            border-radius: 50%;
            &:hover {
                background-color: color(secondary,.1);
            }
            svg {
                transition-duration: 300ms;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-property: opacity, transform;
                path {
                    fill: color(dark);
                }
            }
        }
    }

    &-menu {
        margin-top: 5px;
        padding: 4px; // for outline
        background-color: #fff;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        min-width: 100%;
        // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        //     0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
        transform: rotateX(-90deg);
        transform-origin: center top;
        opacity: 0;
        pointer-events: none;
        will-change: transform, opacity;
        max-height: 12rem;
        overflow-y: auto;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: opacity, transform;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.1);
            transition: color 0.2s ease;
            border: none;
        }
        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.02);
            border-radius: 0;
        }
    }

    &-item {
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        padding: 6px 6px;
        color: color(dark);
        cursor: pointer;
        // line-height: 1.2;
        // transition: background-color 0.3s ease;
        white-space: nowrap;
        &.active {
            background-color: rgba(0, 0, 0, 0.1);
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        &-title {
            flex: 1;
            font-size: 14px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            // &::first-letter {
            //     text-transform: uppercase;
            // }
            &-prefix {
                font-size: 8px;
                font-weight: 400;
            }
            &.active {
                color: color(primary) !important;
            }
        }
        &:hover:not(.active) {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    &-sm {
        .select {
            &-toggle {
                padding: 3px 10px;
                padding-right: 30px;
                min-width: 57px;
                &-title {
                    font-size: 12px !important;
                }
                &-toggle,
                .endIcon {
                    // margin-left: 0;
                }
            }
            &-item {
                padding: 6px;
                &-title {
                    font-size: 12px;
                }
            }
        }
    }
}
