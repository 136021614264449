.tabs {
    position: relative;
    // border: 1px solid #dee2e6;
    padding: 15px;
    // gap: 20px;
    
    display: flex;
    flex-direction: column;
    @include mq(576px){
        padding: 30px;
    }
    @include mq(768px){
        flex-direction: row;
    }
    .nav-tabs {
        // margin-bottom: 1rem;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @include mq(576px){
            margin-right: 50px;
        }
        @include mq(768px){
            width: 170px;
            flex-direction: column;
        }
        .nav-link {
            white-space: nowrap;
            // background: 0 0;
            background: none;
            user-select: none;
            width: 100%;
            justify-content: flex-start;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            cursor: pointer;
            font-size: 12px;
            color: color(dark,.8);
            @include mq(576px) {
                font-size: 14px;
            }
            @include mq(768px){
                margin-bottom: 25px;
            }
            &:hover:not(.active) {
                color: #000;
                svg path {
                }
            }
            .ripple {
                opacity: 0.05 !important;
            }
            .startIcon {
                display: none;
                @include mq(576px) {
                    display: flex;
                }
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: none;
                        stroke: currentColor;
                    }
                    
                }
            }
            // &:hover {
                //     border-color: #e9ecef #e9ecef #dee2e6;
            //     // isolation: isolate;
            // }
            &.active {
                color: #000;
                // font-weight: bold;
                // background-color: #fff;
                // border-color: #dee2e6 #dee2e6 #fff;
            }
            // &.active {
                //     .ripple {
                    //         // background-color: inherit !important;
                    //         // transition: background-color 1s ease-out !important;
                    //     }
            // }
            // &:active {
            //     .ripple {
            //         // background-color: color(primary) !important;
            //     }
            // }
        }
        .indicator {
            margin-top: 1.5px;
            position: absolute;
            top: 100%;
            // transform: translateY(50%);
            transform: translateY(35%);
            right: 4px;
            left: auto;
            transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            display: none;
            @include mq(768px) {
                display: block;
            }

            svg path {
                fill: currentColor;
            }
        }
    }

    .loading-message {
        font-size: 10px;
        color: color(danger);
        opacity: 1;
        position: absolute;
        left: 8px;
        top: 33px;
    }
    .tab-content {
        flex: 1;
        padding-top: 20px;
        min-width: 0;
        @include mq(768px){
            padding-top: 0;
            padding-left: 20px;

        }
        // border: 1px solid color(muted,.5);
        // border-width: 0 0 0 1px;
    }

    .tab-content > .tab-pane {
        display: none;
    }

    .tab-content > .active {
        display: block;
    }

    .fade {
        transition: opacity 0.3s linear;
        &:not(.show) {
            opacity: 0;
        }
    }

    &:not(.swiper-tabs) {
        .nav {
            // display: flex;  // remove if swiper version active
        }
    }

    // SWIPER
    &.swiper-tabs {
        .carousel {
            margin-bottom: -1px;
        }
        .swiper-slide {
            width: auto !important;
        }
        .carousel-angles {
            width: 100%;
        }
        .carousel-angle {
            border-radius: 3px;
            background-color: #fff;
        }
    }
}
