.form-check {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    &.disabled {
        pointer-events: none;
        opacity: 0.3 !important;
    }
    &-input {
        display: none;
        &:checked ~ .wrapper .form-check-switch {
            color: color(primary);
            &:before,
            &:after {
                background-color: currentColor;
            }
            &:before {
                background-color: color(primary, 0.3);
            }
            &:after {
                transform: translate(15px, -50%);
            }
        }
    }
    & > .wrapper {
        width: 40px;
        height: 38px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            .form-check-switch::after {
                box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.05);
            }
        }
    }
    &-switch {
        color: color(primary);
        position: relative;
        width: 32px;
        height: 16px;
        color: rgba(0, 0, 0, 1);
        border: 1px solid color(primary);
        border-radius: 30px;
        &:before {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0.5;
            border-radius: 100px;
            width: 100%;
            height: 100%;
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 2px;
            transform: translateY(-50%);
            background-color: #fff;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    }
    &-label {
        user-select: none;
        font-size: 14px;
        font-weight: bold;
    }
}
