
.blockchains-table {
    width: 100%;
    font-size: 12px;
    // color: color(dark);
    border-collapse: collapse;
    margin: 10px 0;
    @include mq(768px) {
        font-size: 14px;
    }
    .table {
        &-row {
            &-marked {
                background-color: color(purple);
                color: #fff;
            }
        }
        &-th {
            &-details {
                .original-popup {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
            &-highlight {
                .form-check {
                    margin-left: 0;
                }
            }

          
        }
        &-td {
            &-name {
                font-weight: bold;
            }
            &-transaction {
                max-width: 200px;
                width: 50%;
                .wrapper {
                    display: flex;
                    align-items: center;
                }
                span {
                    display: block;
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    width: 100%;
                }
                // .original-popup {
                //     display: block;
                // }
                // .blockHash {
                    // text-overflow: ellipsis;
                    // overflow-x: hidden;
                    // width: 100%;
                // }
            }
            &-hush {
                width: 25px;
            }
            &-highlight {
                .checkbox-wrapper {
                    // background-color: rgba(255,255,255,.3);
                    // border-radius: 50%;
                }
                .form-check {
                    margin-left: 0;
                }
                .spin-loading {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        
    }
    tbody {
        transition: opacity 0.3s ease;
        &.hide {
            opacity: 0;
        }
    }
    tr {
        // border-collapse: collapse;
        &:not(:last-of-type) {
            // box-shadow: 0px 0px 2px color(light);
            border-bottom: 1px solid color(light);
        }
    }
    th {
        // text-align: left;
        font-weight: bold;
    }
    td {
    }
    th,
    td {
        padding: 13px 10px;
        white-space: nowrap;
        // vertical-align: top;
    }

    .with-sort {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        &:hover {
            .sort-btn {
                svg path {
                    fill: color(dark);
                }
            }
        }
        
       
    }

    .sort-btn {
        display: flex;
        &.active {
            svg path {
                fill: color(dark);
            }
        }
        svg {

            width: 14px;
            margin: 3px 0px;
            path {
                fill: color(secondary, 0.5);
            }
        }
    }
}
