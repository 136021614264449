.campaign-page {
    .campaign-title {
        margin-bottom: 0;
    }
    .link-wrapper {
        background-color: color(light, 0.2);
        padding: 10px 15px;
        border-radius: 8px;
        position: relative;
        img {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            max-width: 40px;
            opacity: 0.3;
            filter: grayscale(100%);
        }
    }
    & > .row {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .right-bar-wrapper {
        display: flex;
        width: fit-content;
        margin-left: auto;
        position: relative;
        z-index: 2;
    }
    .dropdown {
        z-index: 1;
        @include mq(520px) {
            margin-left: auto;
            &-menu {
                right: 0;
                left: auto;
            }
        }
    }

    .with-sort {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        &:hover {
            .sort-btn {
                svg path {
                    fill: color(dark);
                }
            }
        }
        
       
    }
    .sort-btn {
        display: flex;
        &.active {
            svg path {
                fill: color(dark);
            }
        }
        svg {

            width: 14px;
            margin: 3px 0px;
            path {
                fill: color(secondary, 0.5);
            }
        }
    }

    .clickable {
        cursor: pointer;
        user-select: none ;
    }

    .table {
        border-collapse: collapse;
        width: 100%;
        font-size: 12px;
        // color: color(dark);
        margin: 10px 0;
        @include mq(768px) {
            font-size: 14px;
        }
        &-th {
            &-details {
                .original-popup {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
        }
        &-td {
            &-name {
                font-weight: 600;
                font-size: 13px;
                white-space: nowrap;
                max-width: 200px;
                span {
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
            &-hush {
                width: 25px;
            }
        }
        tbody {
            transition: opacity 0.3s ease;
            &.hide {
                opacity: 0;
            }
            tr {
            }
        }
        tr {
            &:not(:last-of-type) {
                // box-shadow: 0px 0px 2px color(light);
                border-bottom: 1px solid color(light);
            }
            &.campaign-details-tr {
            }
        }
        th {
            text-align: left;
            font-weight: bold;
        }
        td {
        }
        th,
        td {
            padding: 13px 10px;
            white-space: nowrap;
            // vertical-align: top;
        }
    }
    .ads-td-image {
        width: 0;
        text-align: center;
        .content {
            background-color: color(light);
            width: fit-content;
            margin: 0 auto;
            border-radius: 5px;
            overflow: hidden;
            img {
                border-radius: 3px;
                display: block;
                width: 40px;
                height: 30px;
                object-fit: cover;
                cursor: pointer;
            }
        }
    }
    .image-tooltip {
        padding: 0px !important;
        border-radius: 35px !important;
        max-width: 300px;
        overflow: hidden;
        .image-wrapper {
            position: relative;
            width: 300px;
            height: 270px;
            // background-color: color(light);
            img {
                position: absolute !important;
                width: 100% !important;
                height: 100% !important;
                top: 0 !important;
                left: 0 !important;
                object-fit: contain !important;
                // border-radius: 0 !important;
                display: block;
                // width: 100% !important;
            }
        }
    }
    .campaign-tabs {
        padding: 0;
        flex-direction: column;
        box-shadow: none;
        @include mq(520px) {
            margin-top: -47px;
        }
        .nav-tabs {
            flex-direction: row;
            gap: 3px;
            // margin-left: auto;
            // margin-right: 0;
            margin-bottom: 30px;
            width: 100%;

            @include mq(520px) {
                max-width: 330px;
                width: fit-content;
            }
            .nav-link {
                // background-color: color(light, 0.3);
                border-radius: 4px;
                line-height: 1;
                justify-content: center;
                margin-bottom: 0;
                font-size: 12px;
                min-width: auto;
                &.active {
                    background-color: color(light, 0.6);
                }
                &:hover:not(.active) {
                    background-color: color(light, 0.3);
                }
            }
        }
        .indicator {
            display: none;
        }

        .divider {
            display: none;
        }
        .tab-content {
            padding: 0;
        }

        .tab-pane.fade {
            // transition: .3s ease .1s;
            transition: 1s ease 0.3s;
        }
        // CAROUSEL

        .carousel {
            width: 100%;
        }
        .swiper {
            margin-right: 0;
            position: relative;
            z-index: 0;
            .swiper-slide {
                &:first-child {
                    margin-left: 20px;
                }
                &:last-child {
                    margin-right: 20px;
                }
            }
            &:after,
            &:before {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                width: 20px;
                z-index: 9999;
                pointer-events: none;
            }
            &:before {
                box-shadow: inset 15px 0 10px #fff;
            }
            &:after {
                box-shadow: inset -15px 0 10px #fff;
                right: 0;
            }
        }
        .carousel {
            margin-bottom: -1px;
            display: flex !important;
            align-items: center;
            gap: 5px;
        }
        .swiper-slide {
            width: auto !important;
        }
        .carousel-angles {
            // width: 100%;
            all: unset;
            display: flex;
            gap: 5px;
            margin: 0;
        }
        .carousel-angle {
            border-radius: 3px;
            background-color: #fff;
            width: 20px;
            height: 20px;
            box-shadow: none;
            border-radius: 50%;
            &:hover {
                background-color: color(secondary, 0.1);
            }
            svg {
                path {
                    fill: color(dark) !important;
                }
            }
        }
    }
}
