.support-page {
    font-family: Poppins;
    .tabs {
        margin-top: 50px;
        gap: 20px 50px;
        &.shadow {
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            border: none;
        }
    }
    .divider {
        display: none;
    }
    .nav-tabs {
        margin-right: 0;
        width: auto;
        gap: 20px 10px;
        justify-content: center;
        align-items: flex-start !important;
        @include mq(768px) {
            justify-content: flex-start;
        }
        .indicator {
            // right: auto;
            left: 0px;
            background-color: color(primary);
            width: 130px;
            height: 130px;
            bottom: 50px;
            border-radius: 5px;
            margin-top: -45px;
            svg {
                display: none;
            }
            display: none;
            @include mq(768px) {
                display: block;
            }
        }
        .nav-item {
            flex: 1;
            @include mq(768px) {
                flex: 0 1 auto;
            }
        }

        .nav-item.active {
            &,
            & + .nav-item,
            & + .nav-item + .nav-item,
            & + .nav-item + .nav-item + .nav-item {
                .startIcon {
                    // display: none;
                }
            }
        }
        .nav-link {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            padding: 0;
            margin: 0;
            flex-direction: column;
            font-size: 12px;
            color: color(secondary);
            font-weight: 600 !important;
            white-space: wrap;
            line-height: 1.3;
            gap: 10px;
            padding: 20px;
            z-index: 2;
            height: 100%;
            transition: 0.3s ease;
            height: 100px;
            @include mq(768px) {
                height: 130px;
                width: 130px;
                font-size: 13px;
            }
            &.active {
                color: #fff;
                background-color: color(primary);
                @include mq(768px) {
                    background-color: transparent;
                }
            }
            .startIcon {
                margin: 0 auto;
                display: block;
            }
            svg {
                width: 30px !important;
                height: 30px !important;
                path {
                    fill: currentColor !important;
                }
            }
        }

        .startIcon {
        }
    }
    .tab-content {
        // padding: 15px;
        @include mq(768px) {
        }
    }
    // TABS

    // GET IN TOUCH
    .get-in-touch {
        &-title {
            margin-bottom: 30px;
        }
        .cards-group {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
        }
    }

    // GENERAL QUESTIONS
    .general-questions {
        &-title {
            margin-bottom: 50px;
        }
    }

    // ADVERTISING POLICY
    .advertising-policy {
        &-title {
            margin-bottom: 50px;
        }
    }

    .question {
        margin-bottom: 40px;
        display: flex;
        gap: 15px;
        .wrapper {
            flex: 1;
        }
        &-icon {
            // width: 16px;
            // height: 16px;
            svg {
                width: 16px;
                height: auto;
                path {
                    // fill: color(success);
                    fill: rgb(52, 195, 143);
                }
            }
        }
        &-title {
            font-size: 14px;
            font-weight: 500;
            color: color(secondary);
            margin-bottom: 10px;
        }
        &-text {
            font-size: 13px;
            color: color(muted);
            line-height: 1.6;
        }
    }
}
