.install-pixel-tab {
    &-title {
        font-weight: 500;
        margin-bottom: 10px;
    }
    .tabs {
        padding: 0;
        border: none;
    }

    .nav-tabs {
        flex-direction: row;
        width: 100%;
        gap: 8px;
        align-items: center;
        margin-bottom: 15px;

        display: none;
    }
    .nav-item {
        .nav-link {
            border-radius: 5px;
            font-size: 12px;
            padding: 1px 10px;
            // line-height: ;
            border: 1px solid color(light, 0.5);
            background-color: color(light, 0.2);
            margin-bottom: 0;
            // display: flex;
            // align-items: center;
            .startIcon {
                display: flex !important;
            }
            &.active {
                // color: color(primary);
                // border-color: color(primary);
                color: color(pale-dark);
                border-color: color(pale-dark);
            }
            svg {
                height: 10px !important;
                width: auto !important;
                path {
                    fill: currentColor !important;
                }
            }
            &:hover {
                background-color: color(light, 0.3);
            }
        }
    }
    &-footer {
        .btn-group {
           margin-left: auto;
           width: fit-content;
        }
    }
}


.install-pixel-modal {
    max-width: 450px;
    .btn-group {
        display: flex;
        gap: 10px;
    }
    .modal {
        &-body {
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}