.zone-row {
    margin-bottom: 20px;
    table {
        thead {
            tr {
                background-color: rgba(0, 0, 0, 0.1);
                color: #000;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
            }
        }
    }

    .add-new-zone-drawer{

    }
    .drawer-toggler {
        margin-left: auto;
        display: flex !important;
        width: fit-content ;

    }
    .add-new-zone-btn {
    }
}
