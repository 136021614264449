.info-popup {
    position: relative;
    width: 11px;
    height: 11px;
    text-align: center;
    border-radius: 50%;
    font-weight: 400;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    // font-size: 7px;
    // border: 1px solid color(dark);
    transition: transform 0.3s ease, background-color 0.3s ease;

    &:hover {
        background-color: color(success, 0.3);
        .popup-content {
            opacity: 1;
            transform: translateX(-50%) scale(1);
            pointer-events: auto !important;
            &.left,
            &.right,
            &.extended {
                transform: scale(1);
            }
        }
    }
    &-dark {
        // border: 1px solid color(dark);
        color: color(dark);
        border: 1px solid color(dark);
        &:hover {
            background-color: rgba(0, 0, 0, .3);
        }
    }
    & > svg {
        width: auto;
        height: 7.5px;
        object-fit: contain;
        margin-top: -1px;
        // margin-right: -1px;
        path {
            fill: color(dark) !important;
            stroke: color(dark) !important;
        }
    }

    .popup {
        &-content {
            text-transform: none;
            transform-origin: bottom center;
            pointer-events: none;
            position: absolute;
            opacity: 0;
            font-size: 10px;
            font-weight: 500;
            transition: 0.3s ease;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 3px;
            padding: 6px;
            color: #000;
            box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
            width: 100vw;
            // max-width: 160px;
            width: 200px;
            max-width: max-content;
            line-height: 1.5;
            text-align: left;
            bottom: 100%;
            left: 50%;
            margin-bottom: 10px;
            transform: translateX(-50%) scale(0.7);
            
            &.left {
                right: -7px;
                left: auto;
                transform:  scale(0.7);
                transform-origin:bottom right;
                & > svg {
                    right: 10px;
                    left: auto;
                    transform: rotateZ(-90deg);
                }
            }
            
          
            & > svg {
                width: 7px;
                height: auto;
                object-fit: contain;
                position: absolute;
                left: 50%;
                top: 100%;
                transform: translateX(-50%) rotateZ(-90deg);
                margin-top: -7px;
                path {
                    fill: #fff !important;
                }
            }
        }
    }
}
