.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 997;
}

.drawer-overlay.open {
  opacity: 1;
  pointer-events: auto;
  }

.drawer-content {
  padding: 20px;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: calc(100vh - 15px * 2);
  background-color: #fff;
  overflow-y: auto;
  transition: transform .5s ease, background-color 1s ease;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: transform 1s cubic-bezier(0, 0, 0.27, 1.2);
  margin-top: 15px;
  margin-bottom: 15px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transform: translateX(100%);
}

.drawer-content.open {
 
 transition-delay: 0.3s;
   transition: transform .5s ease 0.3s, background-color 1s ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem,
      rgba(0, 0, 0, 0.6) 0rem 0.25rem 0.375rem -0.125rem;
  right: 0;
  transform: translateX(0%);
  }
  
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
}

.input-group label span {
  font-size: 14px;
  position: relative;
  display: inline;
  padding: 7px 8px 12px 7px;
  top: -1px;
}