.tracking-page {
    .panel-d  {
        width: 1000px;
        height: 50px;
        background-color: color(pale-dark);
    }
    .scroll {
        padding: 0;
        border-radius: 0;
        min-height: 0;
    }
    .drawer-toggler {
        display: inline-block;
     
    }
    .tracking-cover {
        width: 100%;
        max-width: 400px;
        height: auto;
        // margin: 0 auto;
        // margin-left: auto;
        display: block;
    }
}
.conversion-table {
    width: 100%;
    font-size: 12px;
    // color: color(dark);
    border-collapse: collapse;
    // margin: 10px 0;
    @include mq(768px) {
        font-size: 14px;
    }

    border-radius: 5px;
    overflow: hidden;

    .table {
        &-row {
        }
        &-th {
            &-settings {
                .settings-icon {
                    width: 12px;
                    height: 12px;
                    margin: 0 auto;
                    display: inline-block;
                }
            }
        }
        &-td {
            &-name {
                max-width: 200px;
                span {
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }

            &-highlight {
                .spin-loading {
                    width: 25px;
                    height: 25px;
                }
            }
            &-settings {
            }
        }
        &-tr {
        }
    }
    thead {
        // background-color: rgb(0, 31, 57);
        color: #fff;
        tr {
            background-color: color(pale-dark);

        }
    }
    tbody {
        tr {
        
      
        }
    }
    tr {
        background-color: color(light, 0.15);
        &.nested-tr {
            background-color: color(dark,.1);
            & > td {
                padding: 10px 20px;
            }
            tr {
                background-color: rgba(255,255,255,0.5);
            }
            th,
            td {
                font-size: 12px;
                font-weight: 500;
                padding: 8px 10px !important;
            }
        }
        &:last-of-type {
            border-bottom: 2px solid color(pale-dark);
        }
        &:not(:last-of-type) {
            border-bottom: 1px solid color(light);
        }
    }
    th {
        font-weight: 500;
    }
    td {
        padding: 12px 10px !important;
    }
    th,
    td {
        padding: 8px 10px;
        white-space: nowrap;
    }
}

.website-details-btn {
    transform: rotateZ(90deg);
    border: 1px solid rgba(0,0,0,0.1);
    &.active {
        transform: rotateZ(-90deg);

    }
}