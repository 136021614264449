.referral-page {
    & > .row {
        margin-bottom: 25px;
    }
    svg {
        path {
            fill: currentColor !important;
        }
    }

    hr {
        border-color: color(muted, 0.2);
        border-top: none;
    }

    .field.horizontal {
        @include mq(576px) {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            gap: 0 30px;
            width: 100%;

            & > .wrapper {
                margin-bottom: 0;
                min-width: 140px;
            }
            .input-group {
                flex: 1;
                max-width: 400px;
              
            }
        }
    }
    .payouts-form {
        max-width: 450px;
    }
    .payouts-select {
        width: 100%;
        max-width: 100%;
        .btn {

            border-radius: 100px;
        }
    }
    .payouts-field {
        input {

            border-radius: 100px;
        }
    }
    .payouts-btn {
        border-radius: 100px;
        width: 100%;
    }
    .stored-user-details {
        background-color: color(light,.3);
        padding: 15px 15px;
        border-radius: 10px;
        // display: inline-block;
        min-height: 60px;
        label {
            font-weight: 600;
            font-size: 10px;
        }
        p {
            font-size: 12px;
        }
    }
    .block-share {
        background-color: color(warning, 0.1);
        display: inline-block;
        font-weight: 600;
        padding: 3px 6px;
        // color: color(dark) !important;
        border-radius: 4px;
    }

    // TABS
    .tab-content {
  
    }
    .cover {
    }
    .referral-program {
    }
    .reports {
        .cover {
            width: 250px;
            height: 200px;
            background-image: url("../../assets/images/referral.svg");
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: auto;
        }
        .reports-calendar {
            margin-left: auto;
            margin-bottom: 10px;
            .dropdown-menu {
                left: auto;
                right: 0;
            }
        }
        .reports-scroll {
        }
        .request-btn {
        }
        .reports-table {
            border-collapse: collapse;
            width: 100%;
            font-size: 12px;
            // color: color(dark);
            border-radius: 10px;
            overflow: hidden;
            margin: 10px 0;

            @include mq(768px) {
                font-size: 14px;
            }

            .table {
                &-th {
                }
                &-td {
                }
            }
            thead {
                background-color: color(muted, 0.1);
            }
            tbody {
                transition: opacity 0.3s ease;
                &.hide {
                    opacity: 0;
                }
                tr {
                }
            }
            tr {
                &:not(:last-of-type) {
                    // box-shadow: 0px 0px 2px color(light);
                    border-bottom: 1px solid color(light);
                }
                &.campaign-details-tr {
                }
            }
            th {
                text-align: left;
                font-weight: 600;
            }
            td {
            }
            th,
            td {
                padding: 13px 10px;
                white-space: nowrap;
                // vertical-align: top;
            }

            // .payment-icon {
            //     width: 12px;
            //     height: 12px;
            //     margin: 0 auto;
            //     svg {
            //         width: 16px;
            //     }
            // }
            .table-th-request {
                width: 0;
            }
            .table-td-request {

                .btn {
                   
                }
            }
        }

        .campaigns-table-wrapper {
            & > .row {
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: space-between;
                flex-wrap: wrap;
                .dropdown {
                    &-menu {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }

    .paypal {
        .cover {
            background-image: url("../../assets/images/vault.svg");
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: auto;
            height: 115px;
            width: 250px;
        }
        .paypal-banner {
            background-color: color(primary, 0.1);
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 30px;
        }
        hr {
            border-color: color(primary, 0.1);
        }

        
        .paypal-form {
            // max-width: 400px;
            // margin: 0 auto;
        }
        .paypal-btn {
            // margin-bottom: 100px;
            // margin-left: auto;
            // display: block;
            // width: 100%;
        }
    }
}

.paypal-cards-group {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 50px;
    @include mq(576px) {
        flex-direction: row;
    }
}
.paypal-card {
    flex: 1;
    text-align: center;
    max-width: 200px;
    .card {
        &-icon {
            margin: 0 auto 10px;
            border-radius: 50%;
            background-color: color(dark);
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 30px;
                height: 30px;
                path {
                    fill: #fff !important;
                }
            }
        }
        &-title {
            margin-bottom: 10px;
        }
        &-description {
            font-size: 12px !important;
            line-height: 1.5 !important;
        }
    }
}
