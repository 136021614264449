.marketplace-card {
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.03);
    // background-color: color(marketplace);
    background-color: color(dark);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    @include mq(768px) {
        // max-width: 300px;
    }

    &.card-success {
        .card-content-body {
            // background-color: color(success, 0.05);
        }
    }
    &.card-primary {
        .card-content-body {
            // background-color: color(primary, 0.05);
        }
    }
    .price-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-bottom: 10px;
        .price {
        }
    }
    .card {
        &-header {
            // background-color: color(marketplace);
        }
        &-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 90%;
            color: #fff;
            // padding: 8px 10px;
            padding: 16px 10px;
            letter-spacing: 0.1px;
            font-weight: 500;
            cursor: pointer;
            transition: color 0.3s ease;
            font-size: 18px;
            text-align: center;
        }
        &-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 90%;
            line-height: 1;
            margin-bottom: 15px;
        }
        &-body {
        }

        &-price {
            // background-color: rgb(2, 49, 93);
            // background-color: #00417a;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            padding: 4px;
            border-radius: 4px;
            color: #fff;
            // float: right;
            z-index: 1;
            position: relative;
            display: block;
        }
        &-content {
            padding: 10px 30px 15px;
            display: flex;
            flex-direction: column;
            // min-height: 360px;
            min-height: 390px;
            justify-content: space-between;

            @include mq(1800px) {
                min-height: 420px;
            }

            .card-content-body {
                // flex: 1;
                // background-color: color(light, 0.3);
                border-radius: 8px;
                padding: 10px;
                // margin: 5px;
                margin-bottom: 10px;
                color: #fff;
                // border: 2px solid color(light, 0.05);
                // background: linear-gradient(145deg, #002f58, #003869);
                // box-shadow: 20px 20px 148px #002c53, -20px -20px 155px #003c71;
                background-color: #fff;
            }
            hr {
                background-color: #032a4d;
            }
            .card-content-footer {
            }
            .product {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                padding: 2px 5px;
                border-radius: 4px;

                &-title {
                    font-size: 12px;
                    color: color(dark);
                    font-weight: 400;
                    text-align: center;
                }
                &-logo {
                    max-height: 100%;
                    max-width: 160px;
                    // max-width: 100%;
                    object-fit: contain;
                }
            }

            .btn-group {
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: center;
                max-width: 150px;
                margin: 0 auto;
                .order-btn {
                    flex: 1;
                    height: 28px;
                    border: none !important;
                    font-size: 12px;
                    // border-radius: 50px;
                }

                .circle-btn {
                    // box-shadow: 0 0px 3px rgba(255,255,255,.3);
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

                    background-color: rgba(255, 255, 255, 0.9);
                    .ripple {
                        display: none;
                    }
                    width: 28px;
                    height: 28px;
                   
                    &.active {
                        svg path {
                            fill: #fff;
                        }
                    }

                    svg {
                        width: auto !important;
                        height: auto !important;
                        transform: scale(0.7);
                    }
                }
                .btn {
                    &-circle-primary {
                        &.active {
                            background-color: color(primary);
                        }
                    }
                    &-circle-success {
                        &.active {
                            background-color: color(success);
                        }
                    }
                }
            }
        }
    }
    .discount-badge {
        font-size: 12px;
        font-weight: 300;
        color: #fff;
        text-align: center;
    }
    .details-btn {
        margin: 0 auto;
        margin-top: 5px;
        display: flex;
        // color: #00417a;
        // width: 100%;
        // background-color: color(light, 0.1);
        // color: #fff;
        border-radius: 4px;
        margin-bottom: 10px;
        &:hover {
            // background-color: color(light, 0.2);
        }
        svg {
            // width: 20px;
            // height: 20px;
            background-color: #fff;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            padding: 2px;
            margin-left: 5px;
            path {
                fill: color(dark);
            }
        }
    }

    // .order-btn {
    //     width: 100%;
    //     gap: 10px;
    // }
}
