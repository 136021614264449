.drawer {
    padding: 20px;
    z-index: 998;
    position: fixed;
    top: 0;
    width: 100%;
    height: calc(100vh - 15px * 2);
    background-color: #fff;
    overflow-y: auto;
    transition: transform .5s ease, background-color 1s ease;
    margin-top: 15px;
    margin-bottom: 15px;
    &-toggler {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
 
    @include mq(576px) {
        max-width: 300px;
    }
 
    &-fullscreen {
        max-width: none;
        transition: transform 1s cubic-bezier(0, 0, 0.27, 1.2);
        // will-change: transform; // this causes positioning issues with position fixed 
        @include mq(1100px) {
            // width: calc(100% - 295px);
            // margin-top: 92px;
            // height: calc(100vh - 15px - 92px);
            // border-top-left-radius: 20px;
            // border-bottom-left-radius: 20px;
        }
       

    }
    &-left {
        left: 0;
        transform: translateX(-100%);
    }
    &-right {
        right: 0;
        transform: translateX(100%);
        @include mq(576px) {
            &:not(.drawer-fullscreen) {

                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
        }
    }
    &-top {
        left: 0;
        top: 0;
        width: 100%;
        height: 400px;
        transform: translateY(-100%);
        max-width: none;
    }
    &-bottom {
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 400px;
        transform: translateY(100%);
        max-width: none;
    }
    &.show {
        transform: none;
        //  transition-delay: 0.3s;
         transition: transform .5s ease 0.3s, background-color 1s ease;
        box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem,
            rgba(0, 0, 0, 0.6) 0rem 0.25rem 0.375rem -0.125rem;
    }
    &-close {
        display: flex;
        margin-left: auto !important;
        z-index: 2;
        // position: absolute;
        // top: 0;
        // right: 0;
        // cursor: pointer;

        // &:hover {
        //     path {
        //         fill: rgba(0, 0, 0, 1);
        //     }
        // }
        // svg path {
        //     fill: rgba(0, 0, 0, 0.5);
        //     transition: fill 0.3s ease;
        // }
    }

    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
        z-index: 997;
        &.show {
            opacity: 1;
            pointer-events: auto;
        }
    }
}
