.chart-wrapper {
    .chart-title {
        margin-bottom: 30px;
    }
}
.position {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 70%;
    @include mq(768px){
        padding-top: 300px;
    }
    &-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.recharts-surface {
    overflow: visible;
}